import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';

import Header from './header';
import WhatsappButton from './WhatsappButton';

import bodypattern from '../images/pattern.jpg';
import headerImgBottom from '../images/header-bottom.png';

const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${bodypattern});
    
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Container = styled.div`
  max-width: 768px;
  margin: 0 auto;
  background-color: #ffd788; // #ffd788 #fdb927

  @media (min-width: 992px) {
    margin: 20px auto;
    box-shadow: 10px 10px 12px -5px rgba(0, 0, 0, 0.38);
    border-radius: 3px;
  }
`;

const Footer = styled.footer`
  background-image: url("${headerImgBottom}");
  background-position: center;
  background-repeat: no-repeat;
  height: 194px;
  width: 100%;
  padding-top: 95px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  color: #dedede;

  a {
    text-decoration: underline;
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />

        <Container>
          <Header siteTitle={data.site.siteMetadata.title} />

          {children}

          <Footer>
            <p>
              © {new Date().getFullYear()} - {data.site.siteMetadata.title}
            </p>

            <p>
              Cardápio desenvolvido por{' '}
              <a href="https://mznapplab.com">mznapplab</a>
            </p>
          </Footer>
        </Container>

        <WhatsappButton />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
