import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'styled-icons/boxicons-regular/Map';
import { Whatsapp } from 'styled-icons/boxicons-logos/Whatsapp';
import { FacebookSquare } from 'styled-icons/boxicons-logos/FacebookSquare';
import { Instagram } from 'styled-icons/boxicons-logos/Instagram';
import { Clock } from 'styled-icons/fa-regular/Clock';
import { PhoneCall } from 'styled-icons/boxicons-regular/PhoneCall';

import headerImgTop from '../images/header-top.png';

const HeaderWrapper = styled.header`
  text-align: center;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  grid-template-rows: auto auto auto;
  font-size: 0.875rem;
`;

const HeaderBgTop = styled.div`
  background-image: url("${headerImgTop}");
  background-position: center;
  background-repeat: no-repeat;
  height: 187px;
  width: 100%;

  grid-column: 1 / 4;
  grid-row: 1 / 2;
`;

const Social = styled.div`
  color: #f8e831;
  padding: 9px 0;
  margin-top: 10px;

  grid-column: 2 / 3;
  grid-row: 1 / 2;

  a {
    padding: 0 5px;
  }

  @media (max-width: 330px) {
    margin-top: 0;
  }
`;

const Phones = styled.p`
  color: #fafafa;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 53px;

  grid-column: 2 / 3;
  grid-row: 1 / 2;

  span {
    white-space: nowrap;
  }

  @media (max-width: 330px) {
    margin-top: 43px;
  }
`;

const ImageWrapper = styled.div`
  grid-column: 1 / 4;
  grid-row: 2 / 3;
  margin-top: -35px;
  margin-bottom: 35px;

  .gatsby-image-wrapper {
    max-width: 330px;
    margin: 0 auto;
  }
`;

const Hours = styled.p`
  margin-bottom: 75px;
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  align-self: end;

  @media (max-width: 371px) {
    margin-bottom: 85px;
  }
`;

const Address = styled.p`
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  align-self: end;
`;

const IconPhoneCall = styled(PhoneCall)`
  margin: 0 2px 3px 2px;
`;

const IconWhatsapp = styled(Whatsapp)`
  margin: 0 2px 3px 2px !important;
`;

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 330, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <HeaderBgTop />
        <Social>
          <a
            href="https://www.facebook.com/DueAmiciPiracicaba/"
            aria-label="Visitar página no Facebook"
          >
            <FacebookSquare size={23} />
          </a>
          <a
            href="https://www.instagram.com/dueamici.oficial/"
            aria-label="Visitar página no Instagram"
          >
            <Instagram size={23} />
          </a>
          <a
            href="https://goo.gl/maps/mDy9QCP7iTN5rdEY7"
            aria-label="Visitar local no Google Maps"
          >
            <Map size={23} />
          </a>
        </Social>

        <Phones>
          <IconPhoneCall size={24} />
          3433.3600 /{' '}
          <span>
            99943.3600
            <IconWhatsapp size={26} />
          </span>
        </Phones>

        <ImageWrapper>
          <Img
            fluid={data.logo.childImageSharp.fluid}
            alt={`Logo ${siteTitle}`}
          />
        </ImageWrapper>

        <Hours>
          <Clock size={15} /> De Domingo a Quinta das 18 às 23:30 <br /> Sexta e
          Sábado das 18 às 0h
        </Hours>

        <Address>
          <Map size={18} /> Avenida dos Marins, 477 - Piracicaba/SP
        </Address>
      </HeaderWrapper>
    )}
  />
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
