import React from 'react';
import styled from 'styled-components';
import { Whatsapp } from 'styled-icons/boxicons-logos/Whatsapp';
import Button from '@material-ui/core/Button';

const StyledButton = styled(Button)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #25d366;
  color: #61070f;
  text-align: center;
  font-size: 0.75rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  z-index: 1200;
  &:hover {
    background-color: #25d366;
    color: #61070f;
  }
`;

const WhatsappButton = () => (
  <StyledButton
    variant="contained"
    color="primary"
    href="https://api.whatsapp.com/send?phone=5519999433600&text=*Ol%C3%A1%20boa%20noite!*%F0%9F%8D%95%20Vi%20o%20card%C3%A1pio%20no%20site%20e%20gostaria%20de%20fazer%20um%20pedido%0A%0A"
    startIcon={<Whatsapp size={32} />}
    aria-label="Click aqui para fazer pedido via whatsapp"
  >
    Fazer <br />
    Pedido
  </StyledButton>
);

export default WhatsappButton;
